.swiper {
    height: 30rem;
}

.swiper-slide img {
    width            : auto;
    height           : auto;
    max-width        : 100%;
    max-height       : 100%;
    -ms-transform    : translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform   : translate(-50%, -50%);
    transform        : translate(-50%, -50%);
    position         : absolute;
    left             : 50%;
    top              : 50%;
}

.swiper-slide {
    text-align: center;
    font-size : 18px;
}

.mySwiper {
    --swiper-navigation-color: #c21998;
}

.swiper-pagination-bullet {
    width     : 1.3rem !important;
    height    : 1.3rem !important;
    font-size : 0.9rem;
    background: rgb(187, 187, 187) !important;
    opacity: 0.8 !important;
}

.swiper-pagination-bullet-active {
    color     : rgb(0, 0, 0);
    background: #c21998 !important;
}

.swiper img {
    color     : transparent;
}
