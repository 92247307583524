.rain {
    width: .2rem .1vw;
    border: none;
    outline: none;
    /*background:#ffffff;*/
    background: transparent;
    padding: 10px;
    //position: relative;
    z-index: -2;
    border-radius: 10px;
    margin: .2rem 0.3vw;
}

.rain:before {
    content: '';
    background: linear-gradient(45deg, #9c0739, #ca20ca, #d6ccfb,  #b9fbd4, #c3eb15, #f49f90, #8EECF5, #fdfdfd, #000000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
    margin: .2rem .1vw;
}


.rain:active{
    color: #000;
}

.rain:active:after{
    background: transparent;
}
.rain:hover:before{
    opacity: 1;
}

.rain:after{
    z-index: -1;
    content: '';
    position: absolute;
    width: .2rem .1vw;
    margin: .2rem .1vw;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

.modal-body {
    background: linear-gradient( #FDE4CF, #90DBF4,#B9FBC0);
    font-weight: bold;
    text-align: center;
}

.modal-head {
    background: linear-gradient(#6e035d, #133538);
    font-weight: bold;
    text-align: center;
    justify-content: center;
}
