
.project-bg-image{

        --myColor1 : #282828;
        --myColor2 : #3c3c3c;
    background-image: url(../img/waveBackgroundBg.png), linear-gradient(to right, var(--myColor2), var(--myColor1));
    background-size: cover;
        color: #0e0091;
        transition: --myColor1 .5s ease-in-out,--myColor2 .5s;
}

.project-card .project-avatar{
    filter: grayscale(100%);
    transition: .2s ease-in-out;
}

.project-card-focus .img-hover-zoom img{
    transform: scale(1.1);
    filter: grayscale(0);
}

.project-avatar {
    min-height: 20rem;
}
