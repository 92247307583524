
.home{
    background-image: url(../img/waveTestBackground.png), linear-gradient(90deg, #1a39e8, #c21998);
    background-size: cover;
    width: 100%;
    height: 720px;
    min-height: 45rem;
    background-repeat: repeat no-repeat;
    overflow: hidden;
    /*bottom: -1px;*/
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.wave {
    --size: 50px;
    --R: calc(var(--size)*1.28);
    --bottomPosition: 50px;

    -webkit-mask:
            radial-gradient(var(--R) at 50% calc(1.8*var(--size)),#000 99%,#0000 101%)
            calc(50% - 2*var(--size)) 0/calc(4*var(--size)) 100%,
            radial-gradient(var(--R) at 50% calc(-.8*var(--size)),#0000 99%,#000 101%)
            50% var(--size)/calc(4*var(--size)) 100% repeat-x;
    /*background: linear-gradient(90deg,red,blue);*/
    background: white;
    height: 200px;
    width: 200%;
    /*padding-top: 25%;*/
    animation: animate-wave 18s alternate-reverse infinite;

}

.layout{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding-top: 80px;
    padding-right:5%;
}
.intro-paragraph{
    /*font-size: 30px;*/
    padding-left: 5%;
    width: 80%;
}

.proImage{
    height: 200px;
    width: 300px;
}

@keyframes animate-wave{
    0%{
        transform: translatex(0) translateY(var(--bottomPosition)) translateZ(0) scaleY(1);
    }
    50%{
        transform: translatex(-25%) translateY(var(--bottomPosition)) translateZ(0) scaleY(0.55);
    }
    100%{
        transform: translatex(-50%) translateY(var(--bottomPosition)) translateZ(0) scaleY(1);
    }
}


.avatar-wobble {
    height       : 16rem;
    border-radius: 80% 20% 81% 19% / 23% 81% 19% 77%;
    transform    : rotate(-2deg);
    animation    : wobble-article 7s ease-in-out alternate infinite;
}

@keyframes wobble-article {
    50% {
        border-radius: 750px 550px 350px 750px / 450px 750px 550px 650px;
    }

    100% {
        border-radius: 750px 250px 750px 250px / 450px 750px 250px 550px;
        transform    : rotate(2deg);
    }
}

@media  only screen and (max-width: 768px){
    .layout{
        display: flex;
        flex-direction: column;
    }
    .intro-paragraph{
        /*font-size: 20px;*/
        width: 80%;
    }
    .proImage{
        height: 200px;
        width: 200px;
    }
}

@media  only screen and (max-width: 425px) {
    .layout{
        display: flex;
        flex-direction: column;
    }
    .intro-paragraph{
        /*font-size: 15px;*/
        width: 80%;
    }
    .proImage{
        height: 200px;
        width: 200px;
        padding-top: 50px;
    }
}
@media only screen and (max-width: 321px){
    intro-paragraph{
        font-size: 15px;
    }
}